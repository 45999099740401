import React from "react";
import PropTypes from "prop-types";
import CommonContent from "../CommonContent";
import CardImage from "../CardImage";
import StyledCard from "./style";

const Card = ({ background, image, headline, content, button }) => (
	<StyledCard className={`card ${background}`}>
		<CommonContent
			headline={headline}
			content={content}
			wrapperClass="card-content"
			buttons={[button]}
			aligned="top"
		/>
		<CardImage image={image} />
	</StyledCard>
);

Card.propTypes = {
	background: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	headline: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	button: PropTypes.element.isRequired
};

export default Card;
