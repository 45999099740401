import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

const StyledCardImage = styled(props => <BackgroundImage {...props} />)`
	position: absolute !important;
	right: 20px;
	bottom: 0;
	width: 250px;
	height: 320px;
	display: inline-block;
	z-index: 1;
	background-size: contain;
	background-position: center bottom;

	.home-2 {
		width: 250px;
	}
`;

export default StyledCardImage;
