import { useStaticQuery, graphql } from "gatsby";

const cardImageData = () => {
	return useStaticQuery(
		graphql`
			query {
				home_card_1_hd: file(relativePath: { eq: "home-card-1-hd.png" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				home_card_2_hd: file(relativePath: { eq: "home-card-2-hd.png" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				career_card_1_hd: file(relativePath: { eq: "career-card-1-hd.png" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				career_card_2_hd: file(relativePath: { eq: "career-card-2-hd.png" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		`
	);
};

export default cardImageData;
