import styled from "styled-components";

const StyledCareerPhoto = styled.div`
	@media only screen and (min-width: 2350px) {
		.career_photo-section {
			height: 450px;
		}
	}
	.career_photo-section {
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 350px;
	}

	@media only screen and (max-width: 1200px) {
		.career_photo-section {
			width: 100%;
			height: 170px;
			background-size: contain;
			padding: 0 20px;
			box-sizing: border-box;
		}
	}
`;

export default StyledCareerPhoto;
