import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import PropTypes from "prop-types";
import CareerBoard from "./CareerBoard";
import { RightNavButton, LeftNavButton } from "./SliderArrows";

const Div = styled.div`
	width: 650px;
	padding: 20px;
	text-align: center;
	margin: auto;
	padding: 70px 0;
	.career_board-item {
		margin: 0 10px 0 10px;
		padding: 30px;
		width: 500px;
		display: inline-block;
		text-align: left;
		box-shadow: 0 2px 0 rgba(90, 122, 190, 0.12);
		border-radius: 4px;
		border: 1px solid rgba(90, 122, 190, 0.08);
	}
	.career_board-item .label {
		width: 150px;
		text-transform: uppercase;
		letter-spacing: 1px;
		background: #d4e9ff;
		color: #578fcb;
		text-align: center;
		margin-bottom: 15px;
		font-size: 14px;
	}
	.career_board-item .content {
		color: #b0b0b0;
		font-size: 14px;
		line-height: 22px;
	}
	.career_board-item .headline {
		margin-bottom: 20px;
	}
	.career_board-item:hover {
		color: #2aad7c;
	}
	.career_board-item:hover .label {
		background: #c7fce8;
	}
	@media only screen and (max-width: 1200px) {
		width: 100%;
		box-sizing: border-box;
		padding: 50px 20px;
		box-sizing: border-box;
		box-shadow: none;

		.career_board-item {
			width: 100%;
			display: block;
			margin: 0 0 20px 0;
			box-sizing: border-box;
			border: 0;
		}
		.career_board-item .label {
			width: 150px;
		}
		.career_board-item .content {
			padding: 10px;
		}
	}

	@media only screen and (min-width: 2350px) {
		width: 950px;
		padding: 60px 0;
		.career_board-item {
			width: 800px;
		}
		.career_board-item .label {
			width: 250px;
			font-size: 22px;
		}
		.career_board-item .content {
			font-size: 22px;
		}
	}
`;

function CareerBoardSlider({ pageContext }) {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: <LeftNavButton useClass="career" />,
		nextArrow: <RightNavButton useClass="career" />
	};

	return (
		<Div className="career_boards-section">
			<Slider {...settings}>
				<CareerBoard
					label={pageContext.CAREER_BOARD_LABEL_1}
					headline={pageContext.CAREER_BOARD_HEADLINE_1}
					content={pageContext.CAREER_BOARD_CONTENT_1}
				/>
				<CareerBoard
					label={pageContext.CAREER_BOARD_LABEL_2}
					headline={pageContext.CAREER_BOARD_HEADLINE_2}
					content={pageContext.CAREER_BOARD_CONTENT_2}
				/>
				<CareerBoard
					label={pageContext.CAREER_BOARD_LABEL_3}
					headline={pageContext.CAREER_BOARD_HEADLINE_3}
					content={pageContext.CAREER_BOARD_CONTENT_3}
				/>
				<CareerBoard
					label={pageContext.CAREER_BOARD_LABEL_4}
					headline={pageContext.CAREER_BOARD_HEADLINE_4}
					content={pageContext.CAREER_BOARD_CONTENT_4}
				/>
			</Slider>
		</Div>
	);
}

CareerBoardSlider.propTypes = {
	pageContext: PropTypes.shape({
		CAREER_BOARD_LABEL_1: PropTypes.string.isRequired,
		CAREER_BOARD_HEADLINE_1: PropTypes.string.isRequired,
		CAREER_BOARD_CONTENT_1: PropTypes.string.isRequired,
		CAREER_BOARD_LABEL_2: PropTypes.string.isRequired,
		CAREER_BOARD_HEADLINE_2: PropTypes.string.isRequired,
		CAREER_BOARD_CONTENT_2: PropTypes.string.isRequired,
		CAREER_BOARD_LABEL_3: PropTypes.string.isRequired,
		CAREER_BOARD_HEADLINE_3: PropTypes.string.isRequired,
		CAREER_BOARD_CONTENT_3: PropTypes.string.isRequired,
		CAREER_BOARD_LABEL_4: PropTypes.string.isRequired,
		CAREER_BOARD_HEADLINE_4: PropTypes.string.isRequired,
		CAREER_BOARD_CONTENT_4: PropTypes.string.isRequired
	}).isRequired
};

export default CareerBoardSlider;
