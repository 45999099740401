import React from "react";
import PropTypes from "prop-types";
import cardImageData from "../../hooks/card-image-data";
import StyledCardImage from "./style";

const CardImage = ({ image }) => {
	const imageData = cardImageData();
	return (
		<StyledCardImage
			className={`card-image ${image}`}
			fluid={imageData[image].childImageSharp.fluid}
			fadeIn={false}
			style={{ backgroundSize: "contain" }}
		/>
	);
};

CardImage.propTypes = {
	image: PropTypes.string.isRequired
};

export default CardImage;
