import React from "react";
import PropTypes from "prop-types";

function CareerBoard({ label, headline, content }) {
	return (
		<div className="career_board-item">
			<div className="label">{label}</div>
			<div className="headline">
				<h2>{headline}</h2>
			</div>
			<div className="content">{content}</div>
		</div>
	);
}

CareerBoard.propTypes = {
	label: PropTypes.string.isRequired,
	headline: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired
};

export default CareerBoard;
