import styled from "styled-components";

const StyledCareerJobsSection = styled.div`
	@media only screen and (min-width: 2350px) {
		h2 {
			margin-bottom: 30px;
		}
		.career_list-item {
			box-shadow: 0 2px 0 rgba(90, 122, 190, 0.12);
			border-radius: 4px;
			border: 1px solid rgba(90, 122, 190, 0.08);
			padding: 40px 30px;
			margin-bottom: 15px;
			font-size: 22px;
		}
		.career_position,
		.career_position-form,
		.career_position-link {
			display: inline-block;
		}
		.career_position,
		.career_position-form {
			text-align: left;
		}
		.career_position,
		.career_position-form {
			width: 300px;
		}
		.career_position-link {
			width: 99px;
		}
	}

	margin: auto;
	text-align: center;
	width: 800px;
	padding: 70px 0;

	h2 {
		margin-bottom: 30px;
	}
	.career_list-item {
		box-shadow: 0 2px 0 rgba(90, 122, 190, 0.12);
		border-radius: 4px;
		border: 1px solid rgba(90, 122, 190, 0.08);
		padding: 20px;
		margin-bottom: 15px;
	}
	.career_position,
	.career_position-form,
	.career_position-link {
		display: inline-block;
	}
	.career_position,
	.career_position-form {
		text-align: left;
	}
	.career_position {
		width: 300px;
		padding-right: 20px;
	}
	.career_position-form {
		width: 300px;
	}
	.career_position-link {
		width: 120px;
	}

	@media only screen and (max-width: 1200px) {
		margin: auto;
		text-align: center;
		width: 100%;
		padding: 50px 0;

		.career_list-item {
			margin-bottom: 15px;
		}
		.career_position,
		.career_position-form,
		.career_position-link {
			display: block;
		}
		.career_position,
		.career_position-form {
			text-align: center;
		}
		.career_position {
			width: auto;
			margin-bottom: 15px;
		}
		.career_position-form {
			display: none;
		}
		.career_position-link {
			width: auto;
		}
	}
`;

export default StyledCareerJobsSection;
