import React from "react";
import styled from "styled-components";
import SVGIcon from "./SVGIcon";

const ArrowButton = styled(props => <button {...props} />)`
	position: absolute;
	z-index: 100;
	background: #b0b0b0;
	color: #ffffff;
	border: 0;
	padding: 0;
	width: 30px;
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
	border-radius: 50%;
	text-align: center;
	margin: 0;
	cursor: pointer;
	outline: none;

	&:hover {
		background: #2aad7c;
		color: #ffffff;
	}
	&.left {
		left: 0;
	}
	&.right {
		right: 0;
	}
	&.process {
		right: 0;
		top: 45%;
	}
	&.career {
		right: 0;
		top: 40%;
	}
`;
export function RightNavButton({ onClick, useClass }) {
	return (
		<ArrowButton
			type="button"
			title="vpravo"
			aria-disabled="true"
			onClick={onClick}
			className={`slider right ${useClass}`}
		>
			<SVGIcon
				name="faChevronRight"
				className="white"
				viewBox="0 100 320 215"
				style={{
					width: "0.625em"
				}}
			/>
		</ArrowButton>
	);
}

export function LeftNavButton({ onClick, useClass }) {
	return (
		<ArrowButton
			type="button"
			title="vlevo"
			aria-disabled="true"
			className={`slider left ${useClass}`}
			onClick={onClick}
		>
			<SVGIcon
				name="faChevronLeft"
				className="white"
				viewBox="0 100 320 215"
				style={{
					width: "0.625em"
				}}
			/>
		</ArrowButton>
	);
}
