import styled from "styled-components";

const StyledCareerUs = styled.div`
	@media only screen and (min-width: 2350px) {
		.career_us-section {
			width: 960px;
		}
	}
	.career_us-section {
		margin: auto;
		text-align: center;
		width: 800px;
		padding: 70px 0;
	}
	.career_us-section h2 {
		margin-bottom: 30px;
	}
	@media only screen and (max-width: 1200px) {
		.career_us-section {
			width: 100%;
			padding: 50px 20px;
			box-sizing: border-box;
		}
	}
`;

export default StyledCareerUs;
