import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import CareerListItem from "../components/CareerListItem";
import Main from "../components/Main";
import ButtonHashLink from "../components/ButtonHashLink";
import CommonContent from "../components/CommonContent";
import Card from "../components/Card";
import CareerBoardSlider from "../components/CareerBoardSlider";
import ContentRow from "../components/ContentRow";
import POSITIONS from "../content/positions";
import SEO from "../components/seo";
import StyledCareerJobsSection from "../components/templateStyles/StyledCareerJobsSection";
import StyledCareerUs from "../components/templateStyles/StyledCareerUs";
import StyledCareerPhoto from "../components/templateStyles/StyledCareerPhoto";
import BGImg from "../components/BGImg";
import StyledContentHeader from "../components/ContentHeader/style";

const CareerCardsSection = styled.div`
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
	padding: 120px 0 50px 0;

	@media only screen and (max-width: 1200px) {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		grid-row-gap: 30px;
		padding: 50px 30px;
		box-sizing: border-box;
	}
	@media only screen and (min-width: 2350px) {
		padding: 100px 0;
		box-sizing: border-box;
		margin: auto;
		text-align: center;

		.card-content {
			position: relative;
			height: 100%;
		}
	}
`;

const Career = ({ data, pageContext }) => {
	return (
		<Layout pageContext={pageContext}>
			<Main className="page-container">
				<SEO
					title={pageContext.CAREER_TITLE}
					description={pageContext.CAREER_DESCRIPTION}
					lang={pageContext.lang}
					keywords={["Android Developer", "iOS Developer"]}
				/>
				<ContentRow rowContentClass="full-size" className="menuTrigger">
					<BGImg
						hookData={data.career_header}
						fadeIn={false}
						className="career_header"
						style={{ height: "100vh" }}
					>
						<StyledContentHeader>
							<CommonContent
								headline={pageContext.CAREER_HEAD_HEADLINE}
								content={pageContext.CAREER_HEAD_CONTENT}
								wrapperClass="header-content"
								contentSize="big"
							/>
						</StyledContentHeader>
					</BGImg>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<CareerCardsSection className="career_cards-section">
						<Card
							background="green"
							headline={pageContext.CAREER_CARD_1_HEADLINE}
							content={pageContext.CAREER_CARD_1_CONTENT}
							button={
								<ButtonHashLink
									link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}?formtype=hr#contact-form`}
									label={pageContext.CAREER_CARD_1_CTA}
									classString="link white-font"
									alt={pageContext.MENU.CONTACT.NAV}
								/>
							}
							image="career_card_1_hd"
						/>
						<Card
							background="dark"
							headline={pageContext.CAREER_CARD_2_HEADLINE}
							content={pageContext.CAREER_CARD_2_CONTENT}
							button={
								<ButtonHashLink
									link={`/${pageContext.lang}/${pageContext.MENU.CONTACT.URI}?formtype=hr#contact-form`}
									label={pageContext.CAREER_CARD_2_CTA}
									classString="link white-font"
									alt={pageContext.MENU.CONTACT.NAV}
								/>
							}
							image="career_card_2_hd"
						/>
					</CareerCardsSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledCareerJobsSection>
						<h2>{pageContext.CAREER_POSITIONS_HEADLINE}</h2>
						{data.career_list.edges.map(careerItem => (
							<CareerListItem
								pageContext={pageContext}
								headline={careerItem.node.frontmatter.title}
								link={careerItem.node.frontmatter.url}
								form={careerItem.node.frontmatter.form}
								key={careerItem.node.frontmatter.title}
							/>
						))}
					</StyledCareerJobsSection>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledCareerPhoto>
						<BGImg
							hookData={data.career_photo_section}
							className="career_photo-section"
							style={{ backgroundSize: "contain" }}
						/>
					</StyledCareerPhoto>
				</ContentRow>
				<ContentRow background="white" rowContentClass="no-padding">
					<StyledCareerUs>
						<CommonContent
							headline={pageContext.CAREER_PROJECTS_HEADLINE}
							content={pageContext.CAREER_PROJECTS_CONTENT}
							wrapperClass="career_us-section"
						/>
					</StyledCareerUs>
				</ContentRow>
				<ContentRow background="white" rowContentClass="full-size">
					<CareerBoardSlider pageContext={pageContext} />
				</ContentRow>
			</Main>
		</Layout>
	);
};

Career.propTypes = {
	pageContext: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.bool,
			PropTypes.object,
			PropTypes.array
		])
	).isRequired
};
export default Career;

export const query = graphql`
	query CareerQuery($lang: String!) {
		career_photo_section: file(relativePath: { eq: "career-photo.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		career_list: allMdx(
			filter: {
				fields: { source: { eq: "career" } }
				frontmatter: {
					isActive: { eq: true }
					isDefault: { eq: false }
					lang: { eq: $lang }
				}
			}
		) {
			edges {
				node {
					frontmatter {
						title
						url
						form
					}
				}
			}
		}
		career_header: file(relativePath: { eq: "career-header.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
