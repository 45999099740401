import React from "react";
import PropTypes from "prop-types";
import ButtonLink from "./ButtonLink";

function CareerListItem({ pageContext, headline, link, form }) {
	return (
		<div className="career_list-item">
			<div className="career_position">
				<strong>
					{headline}
				</strong>
			</div>
			<div className="career_position-form">{form}</div>
			<div className="career_position-link">
				<ButtonLink
					link={`/${pageContext.lang}/${pageContext.MENU.CAREER_DETAIL.URI}/${link}`}
					label="detail"
					classString="link green-font"
				/>
			</div>
		</div>
	);
}

CareerListItem.propTypes = {
	pageContext: PropTypes.object.isRequired,
	headline: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	form: PropTypes.string.isRequired
};

export default CareerListItem;
